import React, { useEffect, useRef } from 'react'
import { PageProps } from 'gatsby'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import { Layout } from '../component/Layout'
import LegalLinks from '../component/page_component/LegalLinks'
import LegalNotice from '../component/page_component/LegalNotice'
import LegalPolicy from '../component/page_component/LegalPolicy'
import LegalCookies from '../component/page_component/LegalCookies'
import { Divider, GridList } from '@material-ui/core'
import { useIntl } from 'gatsby-plugin-intl'

const useStyles = makeStyles((theme) => ({
  legalContainer: {
    textAlign: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    
    [theme.breakpoints.between('md', 'md')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
    },
  },
  legalItemFirst: {
    scrollSnapType: 'y',
    justifyContent: 'center',
    alignItems:'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.between('md', 'md')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  legalItem: {
    scrollSnapType: 'y',
    justifyContent: 'center',
    alignItems:'center',
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.between('md', 'md')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(1),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(1),
    },
  },
  legalDivider: {
    marginTop: theme.spacing(1),
    maxWidth: '600px',
    minWidth: '75px',
    backgroundColor: '#646464',
  },
}))

const Legal: React.FC<PageProps> = (props) => {
  const { location } = props
  const classes = useStyles()
  const intl = useIntl()
  const noticeRef = useRef<HTMLInputElement>(null)
  const policyRef = useRef<HTMLInputElement>(null)
  const cookiesRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.location?.hash &&
      window.location.hash.split('#').length == 2
    ) {
      const id = window.location.hash.split('#')[1]
      if (id === 'notice' && noticeRef && noticeRef.current) {
        noticeRef.current.scrollIntoView()
      }
      if (id === 'privacy-policy' && policyRef && policyRef.current) {
        policyRef.current.scrollIntoView()
      }
      if (id === 'cookie-settings' && cookiesRef && cookiesRef.current) {
        cookiesRef.current.scrollIntoView()
      }
    }
  }, [])

  return (
    <Layout location={location} title={intl.formatMessage({ id: 'PageTitleLegal' })}>
      <LegalLinks />
      <Grid container className={classes.legalContainer}>
        <div id={'notice'} ref={noticeRef} className={classes.legalItemFirst}>
          <LegalNotice isTitleHanging={true} />
        </div>
        <div id={'privacy-policy'} ref={policyRef} className={classes.legalItem}>
          <Grid container justifyContent="center" >
            <Grid sm={4} >
              <Divider className={classes.legalDivider}/>
            </Grid>
          </Grid>
          <LegalPolicy isTitleHanging={true} />
        </div>
        <div id={'cookie-settings'} ref={cookiesRef} className={classes.legalItem}>
          <Grid container justifyContent="center" >
            <Grid sm={4} >
              <Divider className={classes.legalDivider}/>
            </Grid>
          </Grid>
          <LegalCookies isTitleHanging={true} />
        </div>
      </Grid>
    </Layout>
  )
}

export default Legal
